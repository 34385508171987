//水平的柱状图
let option = {
  grid: {
    top: 68,
    left: 140,
    right: 145,
    bottom: 63
  },
  xAxis: {
    type: 'value',
    max: 100,
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      color: '#888888',
      fontSize: '20px',
      overflow: 'break'
    },
    splitLine: {
      show: false
    }
  },
  yAxis: [
    {
      name: '技能',
      nameTextStyle: {
        color: '#000',
        fontSize: '20px',
        fontWeight: 'bold',
        align: 'right'
      },
      type: 'category',
      data: [],
      axisLabel: {
        width: 120,
        height: 300,
        overflow: 'break',
        color: '#888888',
        fontSize: '20px'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#D9D9D9'
        }
      }
    },
    {
      name: '平均掌握率',
      nameTextStyle: {
        color: '#000',
        fontSize: '20px',
        fontWeight: 'bold',
        align: 'left'
      },
      type: 'category',
      data: [],
      axisLine: {
        show: true,
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#FFBB00',
        fontSize: '20px'
      },
      splitLine: {
        show: false
      }
    }
  ],
  series: [
    {
      type: 'bar',
      data: [],
      color: '#B7D8F2',
      itemStyle: {
        borderRadius: 3
      },
      barWidth: 30
    }
  ]
}

export default { option }

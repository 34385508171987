<template>
  <el-dialog class="personal-report-dialog" :visible.sync="visible" top="60px" :before-close="close" @closed="closed" destroy-on-close>
    <div class="print-button back-top" @click="backTop">回到顶部</div>
    <div class="print-button" @click="exportPdf(0)">下载报告</div>
    <template v-for="(report, index) in reportList">
      <div class="report-container" :key="report.id" :id="`examReport${index}`">
        <div class="top">
          <img :src="require('@assets/logo/report-logo.png')" height="38px" alt="" />
          <div>
            https://enter.yuantusoft.com/home
            <img :src="require('@assets/logo/tencent-cloud-qrcode.png')" height="44px" width="44px" alt="" />
          </div>
        </div>
        <div :style="{ opacity: firstPage ? 1 : 0, height: firstPage ? 'auto' : 0 }">
          <h1 style="font-size: 36px; margin-bottom: 40px">{{ organizationName }}-{{ postName }}个人分析报告</h1>
          <div class="block">
            <p class="title">一、基本信息</p>
            <h1><span>1.1</span><span>基本信息</span></h1>
            <ul class="info">
              <li v-for="(item, key, index) in report.examInfo" style="width: 50%" :key="index">{{ item.label }}：{{ item.value }}</li>
            </ul>
            <h1><span>1.2</span><span>测评得分情况</span></h1>
            <div :ref="`scoreBarChart-${report.id}`" style="height: 409px;"></div>
          </div>
        </div>
        <div class="block">
          <div :style="{ opacity: secondPage ? 1 : 0, height: secondPage ? 'auto' : 0 }">
            <p class="title" style="margin-bottom: 0">二、测评结果分析</p>
            <div :ref="`skillOverallRadarChart-${report.id}`" style="height: 490px"></div>
            <el-table :data="report.userExamAbilityRate" class="yt-table">
              <el-table-column label="技能名称" prop="abilityName" align="center" />
              <el-table-column label="掌握率">
                <template slot-scope="scope">{{ $roundFloat(scope.row.userRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="平均掌握率">
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="差异" prop="differenceRate" align="center" />
            </el-table>
            <h2 style="text-align: center; margin-bottom: 20px;">- 个人测评位于整体情况 -</h2>
            <div :ref="`distributionChart-${report.id}`" style="height: 413px"></div>
          </div>
          <div :style="{ opacity: thirdPage ? 1 : 0, height: thirdPage ? 'auto' : 0 }">
            <template v-if="report.subSkillList.length > 0">
              <p class="title" style="margin-bottom: 0">三、详细技能分析</p>
            </template>
            <template v-for="(item, index) in report.subSkillList">
              <div :style="{ marginTop: index === 0 ? '30px' : '80px' }" class="skill-item" :key="item.id">
                <h2>3.{{ index + 1 }} {{ item.abilityName }}详细技能掌握情况</h2>
                <template v-if="item.hasOwnProperty('subAbilityRateVO')">
                  <div
                    class="radar-chart"
                    :class="[`skill-${item.subAbilityRateVO.length > 4 ? 4 : item.subAbilityRateVO.length}`]"
                    :ref="'subSkillRadarChart-' + item.id"
                  ></div>
                  <el-table :data="item.subAbilityRateVO" class="yt-table">
                    <el-table-column label="技能名称" prop="abilityName" align="center" />
                    <el-table-column label="描述" prop="description" align="center" />
                    <el-table-column label="平均掌握率" align="center">
                      <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                    </el-table-column>
                  </el-table>
                  <div
                    class="bar-chart"
                    :ref="'subSkillBarChart-' + item.id"
                    :style="{ height: `${item.subAbilityRateVO.length * 70 + 140}px` }"
                  ></div>
                </template>
              </div>
            </template>
          </div>
          <div :style="{ opacity: fourthPage ? 1 : 0, height: fourthPage ? 'auto' : 0 }">
            <template v-if="report.subSkillList.length > 0">
              <div class="title" style="margin-bottom: 0">
                四、岗位匹配分析
                <div v-for="item in match" class="match">
                  <div class="circle" :style="{ backgroundColor: item.color }"></div>
                  <p class="match-text">{{ item.text }}</p>
                </div>
              </div>
            </template>
            <template v-for="(item, index) in report.departmentMatchVOS">
              <div :style="{ marginTop: index === 0 ? '30px' : '80px' }" class="skill-item">
                <h2 style="margin-bottom:40px; color: #000000 ">4.{{ index + 1 }} {{ item.departmentName }}岗位匹配情况分析</h2>
                <template>
                  <el-table :data="item.departmentRateEvaluationVOS" :span-method="arraySpanMethod" class="yt-table">
                    <el-table-column label="技能" prop="mainSkillName" align="center" class-name="main-skill" />
                    <el-table-column label="子技能" prop="subSkillName" align="center" />
                    <el-table-column label="权重" prop="weight" align="center" />
                    <el-table-column :label="item.departmentName" width="250px" align="center">
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.rateName"
                          class="match-rate"
                          :style="{
                            color: scope.row.qualified === true ? '#4CE47A' : '#FF5050',
                            backgroundColor: scope.row.qualified === true ? '#EFFFF4' : '#FFEBEB'
                          }"
                        >
                          {{ scope.row.rateName }}
                        </span>
                        <span v-else>未配置</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div style="font-size: 20px; color: #999999">源图数字人才管理平台</div>
        <div style="font-size: 20px; color: #999999">- 精准测评 精准培训 精准识别 精准任用 -</div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import barDoubleChart from './reportCharts/barDoubleChart'
import pieChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import { pdfD2 } from '@util/pdfDown2'
import enterEvaluateReportApi from '@api/enterEvaluateReport'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  name: 'PersonalReport',
  data() {
    return {
      visible: false,
      formData: {},
      reportList: [],
      organizationName: '',
      postName: '',
      examInfo: {
        userName: {
          label: '姓名',
          value: ''
        },
        jobNumber: {
          label: '学号',
          value: ''
        },
        score: {
          label: '测评分数',
          value: ''
        },
        ranking: {
          label: '测评排名',
          value: ''
        },
        avgScore: {
          label: '测评平均分',
          value: ''
        },
        submitTime: {
          label: '测评时间',
          value: ''
        },
        duration: {
          label: '测评用时',
          value: ''
        },
        examName: {
          label: '测评主题',
          value: ''
        }
      }, //基本信息
      match: [
        {
          text: '符合',
          color: '#4CE47A'
        },
        {
          text: '不符合',
          color: '#FF5050'
        }
      ],
      firstPage: true,
      secondPage: true,
      thirdPage: true,
      fourthPage: true
    }
  },
  computed: {
    groupNum() {
      //获取分组数据
      return new Set(this.departmentRateEvaluationVOS.map(item => item.mainSkillName))
    }
  },
  methods: {
    open(payload) {
      this.visible = true
      this.formData = payload
      this.$nextTick(() => {
        this.getPersonalReport()
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.reportList = []
    },
    getPersonalReport() {
      enterEvaluateReportApi
        .getPersonalReport(this.formData)
        .then(res => {
          this.reportList = res.res.map(report => {
            let res = {
              id: this.$generateUUID(),
              examInfo: JSON.parse(JSON.stringify(this.examInfo)),
              scoreBarChart: null,
              skillRadarChart: null,
              distributionChart: null,
              ...report
            }
            //基本信息
            Object.keys(res.examInfo).forEach(key => {
              res.examInfo[key].value = report.userExamInfoVO[key]
            })
            res.departmentMatchVOS.forEach(obj => {
              this.departmentRateEvaluationVOS = obj.departmentRateEvaluationVOS
            })
            this.organizationName = res.userExamInfoVO.organizationName
            this.postName = res.userExamInfoVO.postName
            res.userExamAbilityRate = report.userExamAbilityRate.map(item => {
              return {
                id: this.$generateUUID(),
                subSkillRadarChart: null,
                subSkillBarChart: null,
                ...item
              }
            })
            res.subSkillList = report.userExamAbilityRate
              .filter(item => {
                return item.hasOwnProperty('subAbilityRateVO')
              })
              .map(item => {
                return {
                  id: this.$generateUUID(),
                  subSkillRadarChart: null,
                  subSkillBarChart: null,
                  ...item
                }
              })
            return res
          })
          this.$nextTick(() => {
            this.reportList = this.reportList.map(report => {
              //题型
              report.scoreBarChart = this.initScoreBarChart(report)
              //技能总体
              report.skillRadarChart = this.initSkillOverallRadarChart(report)
              //个人位于整体情况
              report.distributionChart = this.initDistributionChart(report)
              //详细技能
              report.subSkillList = report.subSkillList.map(item => {
                if (item.hasOwnProperty('subAbilityRateVO')) {
                  item.subSkillRadarChart = this.initSkillRadarChart(item)
                  item.subSkillBarChart = this.initSkillBarChart(item)
                }
                return item
              })
              return report
            })
          })
        })
        .catch(() => {
          this.visible = false
        })
    },
    initScoreBarChart(report) {
      //题型得分柱状图
      let barOption = JSON.parse(JSON.stringify(barStackChart.option))
      barOption.xAxis.data = report.sectionScoreInfoVOList.map(item => {
        return item.sectionName
      })
      barOption.series[0].data = report.sectionScoreInfoVOList.map(item => {
        return item.score === 0 ? null : item.score
      })
      barOption.series[1].data = report.sectionScoreInfoVOList.map(item => {
        return item.lostScore === 0 ? null : item.lostScore
      })
      let scoreBarChart = this.$echarts.init(this.$refs[`scoreBarChart-${report.id}`][0])
      scoreBarChart.setOption(barOption)
      return scoreBarChart
    },
    initDistributionChart(report) {
      //个人位于整体情况
      let lineOption = JSON.parse(JSON.stringify(barLineChart.option))
      lineOption.legend.data = ['比率']
      lineOption.grid.left = 60
      lineOption.xAxis.data = report.statisticsVOList.map(item => {
        return item.space
      })
      lineOption.xAxis.interval = 0
      lineOption.yAxis[0].max = 1
      lineOption.yAxis[0].axisLabel.formatter = (value, index) => {
        return this.$roundFloat(value, 0) + '%'
      }
      lineOption.yAxis = lineOption.yAxis.slice(0, 1)
      lineOption.series[0] = JSON.parse(JSON.stringify(lineOption.series[1]))
      lineOption.series[0].data = report.statisticsVOList.map(item => {
        return item.frequency
      })
      lineOption.series[0].yAxisIndex = 0
      lineOption.series[0].symbol = 'none'
      lineOption.series[1].name = '个人'
      lineOption.series[1].type = 'scatter'
      delete lineOption.series[1].yAxisIndex
      lineOption.series[1].data = [[report.personalStatisticsVO.space, report.personalStatisticsVO.frequency]]
      lineOption.series[1].color = '#FFC117'
      lineOption.series[1].symbolSize = 11
      lineOption.series[1].label = {
        show: true,
        fontSize: 18,
        position: 'top',
        formatter: ({ data }) => {
          return `分数: ${report.userExamInfoVO.userScore} 比率: ${this.$roundFloat(data[1], 0)}%`
        }
      }
      let distributionChart = this.$echarts.init(this.$refs[`distributionChart-${report.id}`][0])
      distributionChart.setOption(lineOption)
      return distributionChart
    },
    initSkillOverallRadarChart(report) {
      //技能整体掌握雷达图
      let radarOption = JSON.parse(JSON.stringify(radarChart.option))
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.radar[0].indicator = report.userExamAbilityRate.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].data[0].value = report.userExamAbilityRate.map(item => {
        return item.userRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: report.userExamAbilityRate.map(item => {
          return item.averageRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let skillOverallRadarChart = this.$echarts.init(this.$refs[`skillOverallRadarChart-${report.id}`][0])
      skillOverallRadarChart.setOption(radarOption)
      return skillOverallRadarChart
    },
    initSkillRadarChart(skill) {
      //技能详细掌握雷达图
      let radarOption = JSON.parse(JSON.stringify(radarChart.option))
      if (skill.subAbilityRateVO.length < 3) {
        radarOption.radar[0].startAngle = 180
      }
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.radar[0].indicator = skill.subAbilityRateVO.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].data[0].value = skill.subAbilityRateVO.map(item => {
        return item.averageRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: skill.subAbilityRateVO.map(item => {
          return item.userRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let subSkillRadarChart = this.$echarts.init(this.$refs[`subSkillRadarChart-${skill.id}`][0])
      subSkillRadarChart.setOption(radarOption)
      return subSkillRadarChart
    },
    initSkillBarChart(skill) {
      //技能详细掌握柱状图
      let barOption = JSON.parse(JSON.stringify(barDoubleChart.option))
      barOption.yAxis.data = skill.subAbilityRateVO.map(item => {
        return item.abilityName
      })
      barOption.yAxis.axisLabel.interval = 0
      barOption.series[0].name = '掌握率'
      barOption.series[0].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.userRate, 2)
      })
      barOption.series.push({
        name: '平均掌握率',
        type: 'bar',
        data: [],
        label: {
          show: true,
          formatter: '{c}%',
          position: 'right'
        },
        color: '#E8EAEE',
        barWidth: 16,
        barGap: 0
      })
      barOption.series[1].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.averageRate, 2)
      })
      let subSkillBarChart = this.$echarts.init(this.$refs[`subSkillBarChart-${skill.id}`][0])
      subSkillBarChart.setOption(barOption)
      return subSkillBarChart
    },
    exportPdf(index) {
      this.secondPage = false
      this.thirdPage = false
      this.fourthPage = false
      this.reportList = this.reportList.map((item, reportIndex) => {
        item.pageLoading = false
        return item
      })
      this.$nextTick(() => {
        html2canvas(document.getElementById(`examReport${index}`), {
          scale: 2,
          dpi: 450,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
          // 2为上面的scale 缩放了2倍
          let imgX = ((contentWidth + 10) / 2) * 0.75
          let imgY = (contentHeight / 2) * 0.75
          let pdfX = ((contentWidth + 10) / 2) * 0.75
          let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
          let pdf = new JsPDF('', 'pt', [pdfX, pdfY])
          //可动态生成
          pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
          this.firstPage = false
          this.secondPage = true
          this.$nextTick(() => {
            html2canvas(document.getElementById(`examReport${index}`), {
              scale: 2,
              dpi: 450,
              useCORS: true // 跨域请求
            }).then(canvas => {
              let contentWidth = canvas.width
              let contentHeight = canvas.height
              //一页pdf显示html页面生成的canvas高度;
              let pageData = canvas.toDataURL('image/jpeg', 1.0)
              // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
              // 2为上面的scale 缩放了2倍
              let imgX = ((contentWidth + 10) / 2) * 0.75
              let imgY = (contentHeight / 2) * 0.75
              //a4尺寸 暂时没用上
              // let imgWidth = 595.28
              // let imgHeight = (592.28 / contentWidth) * contentHeight
              let pdfX = ((contentWidth + 10) / 2) * 0.75
              let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
              pdf.addPage([pdfX, pdfY])
              //可动态生成
              pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
              this.secondPage = false
              this.thirdPage = true
              this.$nextTick(() => {
                html2canvas(document.getElementById(`examReport${index}`), {
                  scale: 2,
                  dpi: 450,
                  useCORS: true // 跨域请求
                }).then(canvas => {
                  let contentWidth = canvas.width
                  let contentHeight = canvas.height
                  //一页pdf显示html页面生成的canvas高度;
                  let pageData = canvas.toDataURL('image/jpeg', 1.0)
                  // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
                  // 2为上面的scale 缩放了2倍
                  let imgX = ((contentWidth + 10) / 2) * 0.75
                  let imgY = (contentHeight / 2) * 0.75
                  //a4尺寸 暂时没用上
                  // let imgWidth = 595.28
                  // let imgHeight = (592.28 / contentWidth) * contentHeight
                  let pdfX = ((contentWidth + 10) / 2) * 0.75
                  let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
                  pdf.addPage([pdfX, pdfY])
                  //可动态生成
                  pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
                  this.thirdPage = false
                  this.fourthPage = true
                  this.addPage(pdf, 0)
                })
              })
            })
          })
        })
      })
      // this.makePdf(0)
    },
    addPage(pdf, index) {
      this.reportList = this.reportList.map((item, reportIndex) => {
        item.pageLoading = reportIndex === index
        return item
      })
      this.$nextTick(() => {
        html2canvas(document.getElementById(`examReport${index}`), {
          scale: 2,
          dpi: 450,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
          // 2为上面的scale 缩放了2倍
          let imgX = ((contentWidth + 10) / 2) * 0.75
          let imgY = (contentHeight / 2) * 0.75
          //a4尺寸 暂时没用上
          // let imgWidth = 595.28
          // let imgHeight = (592.28 / contentWidth) * contentHeight
          let pdfX = ((contentWidth + 10) / 2) * 0.75
          let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
          pdf.addPage([pdfX, pdfY])
          //可动态生成
          pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
          if (index === this.reportList.length - 1) {
            pdf.save('考试结果分析报告.pdf')
            this.firstPage = true
            this.secondPage = true
            this.thirdPage = true
            this.fourthPage = true
            this.reportList = this.reportList.map(item => {
              item.pageLoading = true
              return item
            })
          } else {
            if (index === 0) {
              this.fourthPage = false
            }
            index++
            this.addPage(pdf, index)
          }
        })
      })
    },
    // makePdf(index) {
    //   let item = this.reportList[index]
    //   pdfD2(
    //     document.getElementById(`examReport${index}`),
    //     `${item.examInfo.userName.value}${item.examInfo.jobNumber.value === undefined ? '' : item.examInfo.jobNumber.value}.pdf`
    //   )
    //     .then(() => {
    //       this.$message.success('导出成功!')
    //       if (index < this.reportList.length - 1) {
    //         index++
    //         this.makePdf(index)
    //       }
    //     })
    //     .catch(() => {
    //       this.$message.error('导出失败!')
    //     })
    //     .finally(() => {
    //       this.$parent.loading = false
    //     })
    // },
    backTop() {
      document.querySelector('.personal-report-dialog').scrollTop = 0
    },
    //table合并标签
    nameGroup(mainSkillName) {
      // 获取相同名称的数量
      return this.departmentRateEvaluationVOS.filter(item => item.mainSkillName === mainSkillName).length
    },
    nameLen(mainSkillName) {
      //根据名称获取名称第一个数据在全部数据中的偏移位置
      const tmp = Array.from(this.groupNum) //tmp每条目标数据 mainSkillName
      const index = tmp.indexOf(mainSkillName) //该名称在全名称中的偏移位置 index对应名称的下标
      let len = 0 //对应每个名称的行数
      for (let i = 0; i < index; i++) {
        len += this.nameGroup(tmp[i])
      }
      return len
    },
    arraySpanMethod(data) {
      //对于表格数据进行分组合并操作，UI组件回调函数
      const { row, rowIndex, columnIndex } = data
      if (columnIndex === 0) {
        //名称列 合并展示区
        const len = this.nameGroup(row.mainSkillName)
        const lenName = this.nameLen(row.mainSkillName)
        if (rowIndex === lenName) {
          //该名称在首位名称行
          return {
            rowspan: len,
            colspan: 1
          }
        } else
          return {
            //该名称不在首位名称行
            rowspan: 0,
            colspan: 0
          }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/reportVer1';
::v-deep .el-dialog {
  width: 1240px;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.print-button {
  position: fixed;
  top: 500px;
  right: 100px;
  width: 140px;
  padding: 25px 40px;
  height: 140px;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  background: #448bff;
  box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  border-radius: 100px;
  cursor: pointer;
}
.back-top {
  background-color: #ff6060;
  box-shadow: 0 3px 6px rgba(255, 159, 159, 0.5);
  top: 330px;
}
.yt-table {
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  ::v-deep.el-table__header {
    thead {
      th {
        background-color: #d9d9d9 !important;
      }
    }
  }
}
::v-deep .main-skill {
  border-right: 1px solid #d9d9d9;
}
.match {
  justify-content: flex-end;
  display: inline-flex;
  font-weight: normal;
  margin-top: 7px;
  margin-right: 30px;
  float: right;
  .circle {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-top: 9px;
    border-radius: 50%;
  }
  .match-text {
    font-size: 20px;
  }
}
.match-rate {
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  padding: 0 15px;
}
</style>

//水平两根的柱状图
let option = {
  legend: {
    show: true
  },
  grid: {
    top: 68,
    left: 140,
    right: 145,
    bottom: 40
  },
  xAxis: {
    type: 'value',
    max: 100,
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      show: false
    }
  },
  yAxis: {
    name: '技能',
    nameTextStyle: {
      color: '#000',
      fontSize: '20px',
      fontWeight: 'bold',
      align: 'right'
    },
    type: 'category',
    data: [],
    axisLabel: {
      width: 120,
      overflow: 'break',
      color: '#888888',
      fontSize: '20px'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    }
  },
  series: [
    {
      name: '平均掌握率',
      type: 'bar',
      data: [],
      label: {
        show: true,
        formatter: '{c}%',
        position: 'right'
      },
      color: '#7CAEEF',
      barWidth: 16
    }
  ]
}

export default { option }

let option = {
  legend: {
    right: 0,
    data: [{ icon: 'circle', name: '掌握率' }],
    itemWidth: 10,
    itemHeight: 10,
    textStyle: {
      fontSize: '20px'
    }
  },
  radar: [
    {
      indicator: [],
      axisName: {
        color: '#000',
        fontSize: '20px'
      },
      radius: 156.5,
      axisLine: {
        show: false
      },
      splitArea: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#cccccc'
        }
      }
    }
  ],
  calculable: true,
  series: [
    {
      type: 'radar',
      symbol: 'circle',
      symbolSize: 10,
      data: [
        {
          value: [],
          name: '掌握率',
          itemStyle: {
            color: '#7CAEEF', //改变折线点的颜色
            lineStyle: {
              color: '#7CAEEF' //改变折线颜色
            }
          }
        }
      ]
    }
  ]
}

export default { option }

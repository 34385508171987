let option = {
  legend: {
    data: ['得分', '失分'],
    itemWidth: 30,
    itemHeight: 16,
    itemGap: 40,
    textStyle: {
      fontSize: '24px'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      interval: 0,
      color: '#888888',
      fontSize: '20px',
      width: 150,
      overflow: 'break'
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      color: '#888888',
      fontSize: '20px'
    },
    axisTick: {
      show: true
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      name: '得分',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#FFFFFF',
        fontSize: '18px'
      },
      barWidth: 40,
      color: '#6BA7F5',
      emphasis: {
        focus: 'series'
      },
      data: []
    },
    {
      name: '失分',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#FFFFFF',
        fontSize: '18px'
      },
      barWidth: 40,
      color: '#FF6060',
      emphasis: {
        focus: 'series'
      },
      data: []
    }
  ]
}
export default { option }
